<template>
  <div class="tiku-question">
    <div class="header">
      <div class="left">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        <div class="up">
          <img :src="timeImg" />
          <div class="label">
            10:25
          </div>
        </div>

        <div class="down">
          练习册P1
        </div>
      </div>
      <div class="right">
        <div class="up">
          <div class="icon-label">
            <img :src="answerSheetImg" />
            <div class="label">
              答题卡
            </div>
          </div>
          <div class="icon-label">
            <img :src="downloadImg" />
            <div class="label">
              下载
            </div>
          </div>
        </div>
        <div class="down"><span class="bold">3 </span> /15</div>
      </div>
    </div>
    <div class="main"></div>

    <div class="option"></div>

    <!-- <headerMask></headerMask> -->
  </div>
</template>

<script>
// Props -》 Methods -》 Data -》Computed -》 Watch
// https://github.com/vuejs/vue/blob/dev/src/core/instance/state.js#L48-L62
import tikuHeaderTerm from "@/components/tikuHeaderTerm.vue";
import footerMenu from "@/components/footerMenu.vue";
import { courseApi } from "@/api/courseApi.js";
export default {
  components: {},
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      timeImg: require("@/assets/images/tiku/time.png"),
      answerSheetImg: require("@/assets/images/tiku/answer-sheet.png"),
      downloadImg: require("@/assets/images/tiku/download.png"),
    };
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.tiku-question {
  .header {
    height: 80px;
    width: 100%;
    flex: 0 0 auto;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 #eeeeee;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.4px;
      display: flex;
      flex-direction: column;
      margin: 0px 0px 0px 20px;
      .up {
        display: flex;
        flex-direction: column;
        margin: 15px 0px 0px 0px;
        img {
          height: 19px;
          width: 19px;
        }
        .label {
          margin: 2px 0px 0px 0px;
          font-size: 9px;
          color: #7f7f7f;
          letter-spacing: 0.2px;
        }
      }

      .down {
        margin: 4px 0px 0px 0px;
        font-size: 16px;
        color: #505051;
        letter-spacing: 0.36px;
      }
    }
    .right {
      width: 70px;
      margin: 0px 20px 0px 0px;
      .up {
        margin: 15px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        .icon-label {
          margin: 0px 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            height: 19px;
            width: 19px;
          }
          .label {
            margin: 2px 0px 0px 0px;
            font-size: 9px;
            color: #7f7f7f;
            letter-spacing: 0.2px;
          }
        }
      }
      .down {
        display: flex;
        flex-direction: row;

        align-content: flex-end;
        justify-content: center;
        font-size: 12px;
        color: #d7d7d7;
        letter-spacing: 0.27px;
        line-height: 22px;
        .bold {
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
        }
      }
    }
  }
}
</style>
